import React from "react"
import Layout from "../components/layout"
import PageTransition from "gatsby-plugin-page-transitions"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <PageTransition
    defaultStyle={{
      transition: `opacity 250ms ease-in-out`,
      opacity: 0,
    }}
    transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
    }}
    transitionTime={100}
  >
    <Layout location={location}>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div id="home">
        <h1 className="cha__text">CHA BLASCO</h1>
      </div>
    </Layout>
  </PageTransition>
)

export default IndexPage
